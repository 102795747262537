<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'||showType=='edit'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作业类型" prop="work_type">
          <a-select :disabled="true" v-model="formData.work_type">
            <a-select-option value="清洁">清洁</a-select-option>
            <a-select-option value="绿化">绿化</a-select-option>
            <a-select-option value="消杀">消杀</a-select-option>
            <a-select-option value="白蚁防治">白蚁防治</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作业子类型" prop="work_child_type">
          <a-select :disabled="true" v-model="formData.work_child_type">
            <a-select-option v-if="formData.work_type==='清洁'" value="保洁">保洁</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="清扫">清扫</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="大做">大做</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="高空">高空</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="专项清洁">专项清洁</a-select-option>
            <a-select-option v-if="formData.work_type==='清洁'" value="垃圾处理">垃圾处理</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="养护">养护</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="病虫害防治">病虫害防治</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="补种">补种</a-select-option>
            <a-select-option v-if="formData.work_type==='绿化'" value="加固">加固</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="灭鼠">灭鼠</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="灭蚊蝇">灭蚊蝇</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="灭蟑">灭蟑</a-select-option>
            <a-select-option v-if="formData.work_type==='消杀'" value="消毒">消毒</a-select-option>
          </a-select>
        </a-form-model-item>
<!--        <a-form-model-item label="作业点名称" prop="point_name">-->
<!--          <a-input :disabled="true" v-model.trim="formData.point_name"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="区域" prop="region">-->
<!--          <a-input :disabled="true" v-model.trim="formData.region"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="楼层" prop="floor">-->
<!--          <a-input :disabled="true" v-model.trim="formData.floor"></a-input>-->
<!--        </a-form-model-item>-->
<!--        <a-form-model-item label="作业内容" prop="content">-->
<!--          <textarea :disabled="true" v-model.trim="formData.content" style="width: 385px" />-->
<!--        </a-form-model-item>-->
        <a-form-model-item label="作业点名称" prop="location">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.location" show-search :filter-option="filterOption" @change="getMonitorNum">
            <a-select-option v-for="(item,index) in workPointList" :key="index" :value="item.point_qrcode">{{item.point_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="作业单位" prop="unit_name">
          <a-input :disabled="true" v-model.trim="formData.unit_name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="摄像机编号" prop="monitor_num">
          <a-input :disabled="true" v-model.trim="formData.monitor_num"></a-input>
        </a-form-model-item>
        <a-form-model-item label="垃圾分类" prop="garbage_type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.garbage_type">
            <a-select-option value="生活垃圾">生活垃圾</a-select-option>
            <a-select-option value="装修垃圾">装修垃圾</a-select-option>
            <a-select-option value="干垃圾">干垃圾</a-select-option>
            <a-select-option value="湿垃圾">湿垃圾</a-select-option>
            <a-select-option value="易腐垃圾">易腐垃圾</a-select-option>
            <a-select-option value="可回收垃圾">可回收垃圾</a-select-option>
            <a-select-option value="有毒有害垃圾">有毒有害垃圾</a-select-option>
            <a-select-option value="其他垃圾">其他垃圾</a-select-option>
            <a-select-option value="无">无</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="清运类型" prop="clear_type">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.clear_type">
            <a-select-option value="收集">收集</a-select-option>
            <a-select-option value="清运">清运</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="单位" prop="unit">
          <a-select :disabled="showType=='detail'" v-model.trim="formData.unit">
            <a-select-option value="桶">桶</a-select-option>
            <a-select-option value="车">车</a-select-option>
            <a-select-option value="其他">其他</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="数量" prop="num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.num" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 385px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  addWorkOrder,
  getWorkOrderListByCondition, getWorkPointListByCondition,
  modifyWorkOrder
} from "A/businessmanagement";
import {getCache} from "U/index";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    addModalData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '350px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum:'',
        work_type:'清洁',
        work_child_type:'垃圾处理',
        work_person:'',
        work_person_account:'',
        work_address:'',
        work_status:'',
        location:'',
        garbage_type:'',
        clear_type:'',
        unit:'',
        num:'',
        monitor_num:'',
        remark:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        work_type: [{required: true, message: '请选择作业类型'}],
        work_child_type: [{required: true, message: '请选择作业子类型'}],
        work_person: [{required: true, message: '请输入作业人员'}],
        work_person_account: [{required: true, message: '请输入作业人员账号'}],
        location: [{required: true, message: '请选择打点位置'}],
        work_status: [{required: true, message: '请选择作业状态'}],
        garbage_type: [{required: true, message: '请选择垃圾分类'}],
        clear_type: [{required: true, message: '请选择清运类型'}],
        unit: [{required: true, message: '请输入单位'}],
        num: [{required: true, message: '请输入数量'}],
      },
      monitorpointList:[],
      workPointList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    this.initDeptOptionsAll();
    this.getQueryCondition();
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getQueryCondition(){
      getWorkPointListByCondition({userdepsid:this.userInfo.userdepid,work_type:this.formData.work_type,work_child_type:this.formData.work_child_type}).then(res=>{
        if(res && res.returncode == '0') {
          this.workPointList=res.item
        }
      })
    },
    getMonitorNum(){
      getWorkPointListByCondition({userdepsid:this.userInfo.userdepid,point_qrcode:this.formData.location}).then(res=>{
        if(res && res.returncode == '0') {
          this.formData.monitor_num=res.item[0].monitor_num
          this.formData.work_address=res.item[0].point_name
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.formData.contract_num=""
    },
    initDetail() {
      if (this.detailData && this.detailData.plan_id) {
        if(this.showType == 'edit' || this.showType == 'detail'||this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              plan_id: this.detailData.plan_id
            }
            getWorkOrderListByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
                if(this.showType === 'paste'){
                  this.formData.plan_status=this.detailData.plan_status
                  this.formData.month_one=this.detailData.month_one
                  this.formData.month_two=this.detailData.month_two
                  this.formData.month_three=this.detailData.month_three
                  this.formData.month_four=this.detailData.month_four
                  this.formData.month_five=this.detailData.month_five
                  this.formData.month_six=this.detailData.month_six
                  this.formData.month_seven=this.detailData.month_seven
                  this.formData.month_eight=this.detailData.month_eight
                  this.formData.month_nine=this.detailData.month_nine
                  this.formData.month_ten=this.detailData.month_ten
                  this.formData.month_eleven=this.detailData.month_eleven
                  this.formData.month_twelve=this.detailData.month_twelve
                }
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit'|| this.showType=='paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.work_person=this.userInfo.username
            this.formData.work_person_account=this.userInfo.useraccount
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addWorkOrder(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'|| this.showType=='paste'){
              this.showLoading();
              modifyWorkOrder(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>