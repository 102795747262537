<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="作业时间区间" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="作业类型" prop="work_type" style="width: 25%">
              <a-select v-model="queryParams.work_type">
                <a-select-option value="清洁">清洁</a-select-option>
                <a-select-option value="绿化">绿化</a-select-option>
                <a-select-option value="消杀">消杀</a-select-option>
                <a-select-option value="白蚁防治">白蚁防治</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="作业子类型" prop="work_child_type" style="width: 25%">
                <a-select v-model="queryParams.work_child_type">
                  <a-select-option v-if="queryParams.work_type==='清洁'" value="保洁">保洁</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='清洁'" value="清扫">清扫</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='清洁'" value="大做">大做</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='清洁'" value="高空">高空</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='清洁'" value="专项清洁">专项清洁</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='清洁'" value="垃圾处理">垃圾处理</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='绿化'" value="养护">养护</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='绿化'" value="病虫害防治">病虫害防治</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='绿化'" value="补种">补种</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='绿化'" value="加固">加固</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='消杀'" value="灭鼠">灭鼠</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='消杀'" value="灭蚊蝇">灭蚊蝇</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='消杀'" value="灭蟑">灭蟑</a-select-option>
                  <a-select-option v-if="queryParams.work_type==='消杀'" value="消毒">消毒</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="作业人员" prop="work_person" style="width: 25%">
                <a-input v-model="queryParams.work_person"></a-input>
              </a-form-model-item>
              <a-form-model-item label="作业点名称" prop="point_name" style="width: 25%">
                <a-input v-model="queryParams.point_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="作业单位" prop="unit_name" style="width: 25%">
                <a-input v-model="queryParams.unit_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="作业时间状态" prop="order_exception" style="width: 25%">
                <a-select v-model="queryParams.order_exception">
                  <a-select-option value="">所有</a-select-option>
                  <a-select-option value="0">正常</a-select-option>
                  <a-select-option value="1">异常</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>垃圾记录填写</a-button>-->
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="order_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="work_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="order_exception" slot-scope="value">
            <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
          </span>
          <span slot="plan_status" slot-scope="value">
            <span :title="value==1?'运行':'停止'">{{value==1?'运行':'停止'}}</span>
          </span>
          <span slot="num" slot-scope="value">
            <span :title="value=='undefined'?'':value">{{value=='undefined'?'':value}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.order_id">详情</a-menu-item>
<!--                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.order_id">修改</a-menu-item>-->
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.order_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="evaluationModalVisible" title="评价" :width="430" :centered="true" @cancel="evaluationModalVisible=false">
      <template slot="footer">
        <a-button @click="evaluationModalVisible=false">关闭</a-button>
        <a-button @click="evaluation()" type="primary">保存</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:17}">
        <a-form-model-item label="物业评价" class="nobg" prop="property_audit_grade">
          <a-radio-group v-model="formData.property_audit_grade" style="width: 400px">
            <a-radio :value="1">不合格</a-radio>
            <a-radio :value="2">合格</a-radio>
            <a-radio :value="3">良好</a-radio>
            <a-radio :value="4">优秀</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="物业评价内容" prop="property_audit_evaluation">
          <a-textarea v-model="formData.property_audit_evaluation"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <detail-modal :visible.sync="modalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
    <add-or-edit-modal :visible.sync="addModalVisible" :add-modal-data="addModalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/environment/workOrder/addOrEditModal";
import {
  deleteWorkOrder, exportWorkOrderExcel, exportWorkOrderInfoExcel,
  getWorkOrderListByCondition, modifyWorkOrder,
} from "A/businessmanagement";
import {orderStepColor, orderStepList, orderSteps} from "@/json/orderSteps";
import DetailModal from "V/businessManagement/environment/workOrder/DetailModal";
import {orderException, orderExceptionColors} from "@/json/maintenance";
export default {
  name: "workOrder",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
    DetailModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      orderStepList,
      orderSteps,
      orderStepColor,
      orderException,
      orderExceptionColors,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        work_type:'',
        work_child_type:'',
        work_person:'',
        work_address:'',
        work_status:'',
        monitor_num:'',
        point_name:'',
        unit_name:'',
        order_exception:'',
        year:'',
        starttime:'',
        endtime:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '作业类型',
          dataIndex: 'work_type',
          key: 'work_type',
          ellipsis: true,
        },
        {
          title: '作业子类型',
          dataIndex: 'work_child_type',
          key: 'work_child_type',
          ellipsis: true,
        },
        {
          title: '作业点名称',
          dataIndex: 'point_name',
          key: 'point_name',
          ellipsis: true,
        },
        {
          title: '作业单位',
          dataIndex: 'unit_name',
          key: 'unit_name',
          ellipsis: true,
        },
        {
          title: '作业人员',
          dataIndex: 'work_person',
          key: 'work_person',
          ellipsis: true,
        },
        {
          title: '作业时间',
          dataIndex: 'work_time',
          key: 'work_time',
          ellipsis: true,
          scopedSlots: { customRender: 'work_time' },
        },
        {
          title: '打点位置',
          dataIndex: 'work_address',
          key: 'work_address',
          ellipsis: true,
          scopedSlots: { customRender: 'work_address' },
        },
        {
          title: '摄像机编号',
          dataIndex: 'monitor_num',
          key: 'monitor_num',
          ellipsis: true,
        },
        {
          title: '作业内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        {
          title: '数量',
          dataIndex: 'num',
          key: 'num',
          ellipsis: true,
          scopedSlots: { customRender: 'num' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          ellipsis: true,
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'order_exception' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      yearOptions: [],
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      queryInstallDate1:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      addModalVisible: false,
      addModalDetailData: null,
      evaluationModalVisible:false,
      formData:{
        order_id:'',
        property_man:'',
        property_audit_grade:'',
        property_audit_evaluation:'',
      },
      //这里面的数据属于必填项
      formRules: {
        property_audit_grade: [{required: true, message: '请选择评价'}],
        property_audit_evaluation: [{required: true, message: '请填写评价内容'}],
      },

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "作业任务"
    },
    breadcrumb() {
      const pages = [{name:"业务管理",path:""}]
      pages.push({name:"环境管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    queryInstallDate1(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime1 = start;
      this.queryParams.endtime1 = end;
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getYearOptions();
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getWorkOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.queryInstallDate = [];
      this.queryInstallDate1 = [];
      this.$refs.queryForm.resetFields();
      this.getYearOptions()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'order_id', order_id);
      if(type == 'delete') {
        this.deleteConfirm(order_id, record)
      }else if(type == 'evaluate'){
        this.formData=record
        this.evaluationModalVisible=true
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }
    },
    addRecord(){
      this.addModalVisible=true
      this.addModalShowType='add'
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportWorkOrderExcel()
      }).catch(()=>{
      });
    },
    exportWorkOrderExcel(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportWorkOrderExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.order_id);
      }).catch(()=>{

      }) ;
    },
    delete(order_id) {
      this.showLoading();
      if(order_id) {
        let params = {
          order_id
        };
        deleteWorkOrder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let startYear = 2023;
      for(let i=100; i>-1; i--) {
        this.yearOptions.push(startYear+i+'');
      }
      this.queryParams.order_date=currentYear+''
    },
    evaluation(){
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          this.formData.property_man=this.userInfo.username
          modifyWorkOrder({...this.formData}).then(res => {
            if (res && res.returncode === '0') {
              this.evaluationModalVisible = false
              this.$message.success("评价成功")
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>

</style>